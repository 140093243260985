import { useEffect, useState } from "react"
import { LOGO } from "../../constants/images"
import { UIInput } from "../../ui/uiinput/UIInput"
import UIButton from "../../ui/uibutton/UIButton"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootStore } from "../../redux/store"
import {
  loginHandler,
  LoginPostDataInterface,
} from "../../redux/actions/login/login.action"

// "email":"ayush@supremeitsolutions.com",
// "password":"Jknglobal@123"

export const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const Login = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { data, isFulfilled } = useSelector((state: RootStore) => state.login)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectUrl = queryParams.get("redirect")

  const [state, setState] = useState<LoginPostDataInterface>({
    email: "",
    password: "",
  })

  const [error, setError] = useState({
    email: "",
    password: "",
  })

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
    if (value) {
      setError((prev) => ({ ...prev, [name]: "" }))
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!emailRegex.test(state.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email format" }))
      return
    }
    if (state.password.length < 6) {
      setError((prev) => ({
        ...prev,
        password: "Password must be at least 6 characters",
      }))
      return
    }
    dispatch(loginHandler(state))
  }

  useEffect(() => {
    if (isFulfilled) {
      if (redirectUrl) {
        const decodedUrl = decodeURIComponent(redirectUrl)
        window.location.replace(decodedUrl)
      } else {
        navigate("/dashboard")
      }
    }
  }, [isFulfilled, redirectUrl, navigate])

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      <div className="loginform">
        <div className="loginform-loginformtop">
          <div className="loginform-loginformtop--loginformtoptext">
            <h1>Welcome Back</h1>
            <h2>
              Sign in to <strong>Easy Voting App</strong> Dashboard
            </h2>
          </div>
          <div className="loginform-loginformtop--loginformtopimage">
            <div className="loginform-loginformtop--loginformtopimage_image bg-[#19206F]">
              <img className="" src={LOGO} alt="FDAPP Logo" height="100%" width="100%" />
            </div>
          </div>
        </div>
        <form
          className="loginform-loginformform"
          method="POST"
          onSubmit={handleFormSubmit}
        >
          <div className="loginform-loginformform--flex">
            <UIInput
              id="email"
              name="email"
              isRequired
              label="Email"
              placeholder="eg. xyz@gmail.com"
              type="email"
              error={error.email}
              onChange={handleInput}
              value={state.email}
            />
            <UIInput
              id="password"
              name="password"
              isRequired
              label="Password"
              placeholder="eg. ********"
              type="password"
              error={error.password}
              onChange={handleInput}
              value={state.password}
            />
          </div>
          <div className="loginform-loginformform--submit">
            <UIButton label="Login" type="primary duotone" />
          </div>
        </form>
      </div>
    </div>
  )
}
