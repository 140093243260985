import {
    EditSponsersInitialValue,
    EditValidationSchema,
  } from "../utils/SponsersValidation"
  import { useFormik } from "formik"
  import React, { useEffect, useRef } from "react"
  import Navbar from "../../../../src/components/navbar/Navbar"
  import PrevBtn from "../../../../src/ui/prevbutton/BackBtn"
  import { UIInput } from "../../../../src/ui/uiinput/UIInput"
  import { useAppDispatch } from "../../../utils/redux.utils"
  import { useSelector } from "react-redux"
  import { dataService } from "../../../utils/axios"
  import { RootStore } from "../../../redux/store"
  import {
    dismissToast,
    errorToast,
    loadingToast,
    successToast,
  } from "../../../utils/toastify"
  import { useNavigate, useParams } from "react-router-dom"
  import { S3_URL } from "../../../constants/constants"
  import { GetSponserIdData, GetOneSponserResetState } from "../../../../src/redux/slices/Sponers/GetOneSponser.slices"
  import { GetSponserByID } from "../../../../src/redux/actions/sponsers/GetoneSponser.action"
  import { AddSponserData } from "../utils/Formdata"
  import { SponserUpdateThunk } from "../../../../src/redux/actions/sponsers/SponserUpdate.action"
  import { TypedSelector } from "../../../utils/redux.utils"
  import { UpdateSponserData ,UpdateSponserResetState } from "../../../../src/redux/slices/Sponers/UpdateSponsers.slices"
  
  const EditSponsers = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const didMount = useRef<boolean>(false)
    const { x_api } = useSelector((state: RootStore) => state.x_api_key)
    const { data: AuthData } = useSelector((state: RootStore) => state.login)
  
    const SponserDataState = TypedSelector(GetSponserIdData)
    const UpdateSponserDataState = TypedSelector(UpdateSponserData)
  
    useEffect(() => {
      dataService.setApiKey(x_api!)
      dataService.setToken(AuthData.token!)
      dispatch(GetSponserByID(id!))
    }, [dispatch, id, navigate, x_api, AuthData.token])
  
    const formik = useFormik({
      initialValues: EditSponsersInitialValue,
      validationSchema: EditValidationSchema,
      onSubmit: async (values) => {
        console.log(values)
        const data: any = await AddSponserData(values)
        updateSponser(data)
      },
    })
  
    const updateSponser = async (formData: any) => {
      if (id) {
        dataService.setApiKey(x_api!)
        dataService.setToken(AuthData.token!)
        await dispatch(SponserUpdateThunk({ id, formData }))
      } else {
        console.error("ID is missing")
      }
    }
  
    useEffect(() => {
      if (!didMount.current) {
        didMount.current = true
        return;
      }
      if (UpdateSponserDataState.isFulfilled) {
        successToast("Sponsor Updated Successfully")
        navigate("/Sponsers")
        dispatch(UpdateSponserResetState())
      } else if (UpdateSponserDataState.isLoading) {
        loadingToast("Updating Sponsor")
      } else if (UpdateSponserDataState.isRejected) {
        errorToast("Error Updating Sponsor")
        dispatch(UpdateSponserResetState())
      }
  
      return () => {
        dismissToast()
      }
    }, [UpdateSponserDataState, dispatch, navigate])
  
    useEffect(() => {
      if (SponserDataState.isFulfilled && SponserDataState.data) {
        formik.setValues({
          title: SponserDataState.data.title || "",
          contact: SponserDataState.data.contact || "",
          Existinglogo: SponserDataState.data.logo
            ? `${S3_URL}${SponserDataState.data.logo}`
            : "",
          logo: null || '',
          shortContent: SponserDataState.data.shortContent || "",
          largeTitle: SponserDataState.data.largeTitle || "",
        })
        dispatch(GetOneSponserResetState())
      }
  
      if (SponserDataState.isRejected) {
        errorToast("Error Fetching Sponsor Details!")
        dispatch(GetOneSponserResetState())
      }
  
      if (SponserDataState.isPending) {
        loadingToast("Fetching Sponsor Details...")
      }
  
      return () => {
        dismissToast()
      }
    }, [SponserDataState, formik, dispatch])
  
    return (
      <div className="framecontainer">
        <div className="framecontainer-content">
          <div className="dashboard">
            <Navbar name="Edit Entry - Sponser" showBars />
          </div>
          <PrevBtn />
          <div className="form-wrapper">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div className="input">
                <UIInput
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="title"
                  label="Title"
                  type="text"
                  placeholder="Enter Sponser Title"
                  formikError={formik}
                />
              </div>
  
              <div className="input">
                <UIInput
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="contact"
                  label="Contact"
                  type="tel"
                  placeholder="Enter Contact Number"
                  formikError={formik}
                />
              </div>
              <div className={`input "border border-red-500 px-3 rounded-xl"}`}>
                <label className="label">Image</label>
                <input
                  name="logo"
                  type="file"
                  onChange={(event: any) => {
                    formik.setFieldValue("logo", event.currentTarget.files[0])
                  }}
                  onBlur={formik.handleBlur}
                  accept="image/*"
                  className="block w-full text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
               file:font-semibold
              file:bg-[#0b005a]/80 file:text-white
              hover:file:bg-[#0b005a]
            "
                />
                {formik.values.logo && formik.values.logo instanceof File ? (
                  <div className="image-preview mt-6">
                    <img
                      src={URL.createObjectURL(formik.values.logo)}
                      width={200}
                      alt="New Preview"
                    />
                  </div>
                ) : formik.values.Existinglogo ? (
                  <div className="image-preview mt-6">
                    <img
                      src={formik.values.Existinglogo}
                      width={200}
                      alt="Existing Judge"
                    />
                  </div>
                ) : null}
  
                {formik.errors.logo && formik.touched.logo && (
                  <div className="error mt-2 text-red-600 text-base">
                    {formik.errors.logo} *
                  </div>
                )}
              </div>
  
              <div className="input textarea">
                <label className="label">Large Title</label>
                <textarea
                  value={formik.values.largeTitle}
                  name="largeTitle"
                  placeholder="Enter your Large Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {formik.errors.largeTitle && formik.touched.largeTitle && (
                  <div className="error mt-2 text-red-600 text-base">
                    {formik.errors.largeTitle} *
                  </div>
                )}
              </div>
  
              <div className="input">
                <UIInput
                  value={formik.values.shortContent}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="shortContent"
                  label="Short Content"
                  type="text"
                  placeholder="Enter Short Content"
                  formikError={formik}
                />
              </div>
              <div className="btn-wrapper  flex items-center justify-center">
                <button
                  type="submit"
                  className={` py-2 px-36 text-white bg-black  btn btn-submit rounded-full duration-200`}
                >
                  Update Sponser
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
  
  export default EditSponsers
  