import React, { ElementRef, useEffect, useRef, useState } from "react"
import { FaCamera } from "react-icons/fa6"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RangeDatePicker } from "../../../components/date-picker/DatePicker"
import Navbar from "../../../components/navbar/Navbar"
import { S3_URL } from "../../../constants/constants"
import { updateCampaignBanner } from "../../../redux/actions/votingCampaign/updateBanner.action"
import { updateCampaignById } from "../../../redux/actions/votingCampaign/updateCampaignById.action"
import { updateCampaignLogo } from "../../../redux/actions/votingCampaign/updateLogo.action"
import { xApiKey } from "../../../redux/slices/XapiKey/xApiKey.slice"
import { loginData } from "../../../redux/slices/login/login.slices"
import { resetUpdateLogo, updateCampaignLogoState } from "../../../redux/slices/votingCampaign/updateLogo.slice"
import { resetCampaignUpdate, updateCampaign } from "../../../redux/slices/votingCampaign/updateVCampaign.slice"
import { Rows } from "../../../redux/slices/votingCampaign/votingCamp.slice"
import PrevBtn from "../../../ui/prevbutton/BackBtn"
import { UIInput } from "../../../ui/uiinput/UIInput"
import { dataService } from "../../../utils/axios"
import { convertIsoToNormalDateTime, convertToIsoDateTime } from "../../../utils/helper"
import { TypedSelector, useAppDispatch } from "../../../utils/redux.utils"
import { errorToast, successToast } from "../../../utils/toastify"
import { updateVCampaignSchema } from "../../../utils/zod/voting_campaign/updateVCampaign.schema"
export const VotingCampaignEditEntry = () => {
  const location = useLocation()
  const campaignData: Rows = location?.state?.campaign
  let { id } = useParams()
  const navigation = useNavigate()
  const loginInfo = TypedSelector(loginData)
  const logoState = TypedSelector(updateCampaignLogoState)
  const xapiKey = TypedSelector(xApiKey)
  const updateCampaignState = TypedSelector(updateCampaign)
  const dispatch = useAppDispatch()
  const fileRef = useRef<ElementRef<"input">>(null)
  const bannerRef = useRef<ElementRef<"input">>(null)
  const [file, setFile] = useState<any>(null)
  const [banner, setBanner] = useState<any>(null)
  const [state, setstate] = useState({
    title: campaignData.title || "",
    description: campaignData.description || "",
    startDateTime: convertIsoToNormalDateTime(campaignData.startDateTime) ?? "",
    endDateTime: convertIsoToNormalDateTime(campaignData.endDateTime) ?? "",
    timeZone: campaignData.timeZone || "GMT"
  })
  const [date, setDate] = useState<string>("")
  const handelchange = (e: any) => {
    const value = e.target.value
    setstate((prev) => ({ ...prev, [e.target.name]: value ?? "" }))
  }
  // updateCampaignById
  useEffect(() => {
    const date = new Date()
    setDate(date.toLocaleDateString())
  }, [])
  useEffect(() => {
    if (loginInfo.data.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo, xapiKey])
  const submithandler = (e: any) => {
    e.preventDefault()
    try {
      const validateData = updateVCampaignSchema.safeParse({
        ...state,
        startDateTime: convertToIsoDateTime(state.startDateTime),
        endDateTime: convertToIsoDateTime(state.endDateTime)
        // startDateTime:(state.startDateTime),
        // endDateTime:(state.endDateTime),
      })
      if (validateData.success)
        dispatch(updateCampaignById({ id: id as string, data: validateData.data }))
      else
        console.log(validateData.error)
    } catch (error) {
      console.log("something went wrong")
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if ((e.target.files[0].size / (1000 * 1000)) > 1) {
        errorToast("Logo should be less than 1mb")
      } else {
        setFile(URL.createObjectURL(e.target.files[0]))
      }
    }
  }
  const handleBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if ((e.target.files[0].size / (1000 * 1000)) > 1) {
        errorToast("Banner should be less than 1mb")
      } else {
        setBanner(URL.createObjectURL(e.target.files[0]))
      }
    }
  }
  const handleFileUpload = () => {
    fileRef.current?.click()
  }
  const handleBannerUpload = () => {
    bannerRef.current?.click()
  }
  useEffect(() => {
    if (updateCampaignState.data?.success) {
      successToast("campaign updated")
      navigation("/votingcampaign")
      dispatch(resetCampaignUpdate())
    }
  }, [updateCampaignState, dispatch, navigation])

  useEffect(() => {
    if (fileRef.current?.files) {
      if (fileRef.current?.files[0]) {
        const pass = {
          id: campaignData.id,
          data: {
            logo: fileRef.current.files[0],
          }
        }
        dataService.setContentType("multipart/form-data")
        dispatch(updateCampaignLogo(pass))
      }
    }
  }, [file])
  useEffect(() => {
    if (bannerRef.current?.files) {
      if (bannerRef.current?.files[0]) {
        const pass = {
          id: campaignData.id,
          data: {
            banner: bannerRef.current.files[0],
          },
        }
        dataService.setContentType("multipart/form-data")
        dispatch(updateCampaignBanner(pass))
      }
    }
  }, [banner])
  useEffect(() => {
    if (logoState.data?.success) {
      navigation("/votingcampaign")
      dispatch(resetUpdateLogo())
    }
    if (logoState.isError) {
      errorToast("something went wrong")
      dispatch(resetUpdateLogo())
    }
  }, [logoState])
  const handleStartDate = (e: string) => {
    setstate((prev) => ({ ...prev, startDateTime: convertToIsoDateTime(e) }))
  }
  const handleEndDate = (e: string) => {
    setstate((prev) => ({ ...prev, endDateTime: convertToIsoDateTime(e) }))
  }
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Edit Entry - Voting Campaign" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <div className="z-30 h-auto relative mb-10 flex overflow-hidden">
            <FaCamera size={20} className="absolute -z-20 right-5 top-5 cursor-pointer text-pink-500 "
              onClick={handleBannerUpload}
            />
            <input type="file" ref={bannerRef} className="hidden" onChange={handleBannerChange} />
            <img src={banner ?? S3_URL.concat(campaignData.banner)} alt="bannerimage" className="absolute w-full -z-30" />
            <div className="overflow-hidden  h-48 w-48 flex items-center justify-center rounded-full border-2 border-gray-300 ">
              <input type="file" ref={fileRef} className="hidden" onChange={handleChange} />
              <FaCamera size={20} className="absolute left-40 bottom-6 cursor-pointer text-pink-500 "
                onClick={handleFileUpload}
              />
              <img src={file ?? S3_URL.concat(campaignData.logo)} alt="Logo" className="object-cover h-48 w-48" />
            </div>
          </div>
          <form className="form">
            <div className="input">
              <UIInput
                value={state.title}
                onChange={handelchange}
                name="title"
                label="Title"
                type="text"
              />
            </div>
            {/* <div className="input">
              <label>Currency</label>
              <select
                value={state.timeZone}
                name="timeZone"
                onChange={handelchange}
              >
                <option value="default">Select Currency</option>
                <option value='GMT'>GMT</option>
                <option value='BST'>BST</option>
              </select>
            </div> */}
            <div className="input">
              <label className="inputfield-label">StartDate</label>
              <RangeDatePicker
                value={state.startDateTime}
                minDate={date}
                maxDate={state?.endDateTime.split('T').at(0)}
                onChange={handleStartDate}
                name='startDateTime'
              />
            </div>
            <div className="input">
              <label className="inputfield-label">EndDate</label>
              <RangeDatePicker
                value={state.endDateTime}
                minDate={state?.startDateTime.split('T').at(0)}
                onChange={handleEndDate}
                name='endDateTime'
              />
            </div>
            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={state.description}
                name="description"
                onChange={handelchange}
              ></textarea>
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button className={`${updateCampaignState.isLoading ? "bg-[#2c2375]" : "bg-[#0b005a]"}  py-2 px-36 text-white  btn btn-submit rounded-full duration-200`}
                disabled={updateCampaignState.isLoading}
                onClick={submithandler}
              >
                {(!updateCampaignState.isLoading && !updateCampaignState.isError) ? "Save" : "Updating..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
